import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import RowData from "./rowData"
// import RowPopup from "../table/rowPopup/rowPopup";
import { updateUrlParam } from "../../table/utils";

function ClickableRowData(props) {
  function onClick(){
    updateUrlParam('row',parseInt(props.id))
    props.setRowPopupData({...props.value,autonumber : props.id});
    props.setIsPopupOpen(true);
    props.setLastOpenedRowId(props.id)
  }


  return (
    <>
      <Box onClick={onClick} sx={{cursor:"pointer"}}>
        <RowData {...props}/>
      </Box>
      {/* {props.activeRowId == props.id && (<RowPopup rowData={{ ...props.value , autonumber : props.id }} closePopup={onClose}/>)} */}
    </>
  );
}

export default React.memo(ClickableRowData, (prevProps, nextProps) => {
    prevProps.heading !== nextProps.heading &&
    prevProps.activeRowId === nextProps.activeRowId && 
    prevProps.id === nextProps.id
});

ClickableRowData.propTypes = {
  heading: PropTypes.string,
  id: PropTypes.string,
  value : PropTypes.any,
  sx : PropTypes.object,
  // activeRowId: PropTypes.number,
  // setActiveRowId: PropTypes.func.isRequired,
  setLastOpenedRowId: PropTypes.func.isRequired,
  setRowPopupData: PropTypes.func.isRequired,
  setIsPopupOpen: PropTypes.func.isRequired,
};
