
import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Button,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import './selectfilepopup.scss';
import { Select, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateCells } from "../store/table/tableThunk";
import { selectFilePopupStyles } from "../muiStyles/muiStyles";
import CustomTextField from "../muiStyles/customTextfield";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactPlayer from 'react-player'
import ImagePreviewAndCompress from "../component/imagePreviewAndCompress/imagePreviewAndCompress";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '25vw',
  bgcolor: "background.paper",
  columnGap: "20px",
  outline: 'none'
};
export default function SelectFilePopup(props) {
  const params = useParams(), dispatch = useDispatch();
  const [imageLink, setImageLink] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [mediaSize, setMediaSize] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const getBlobSize = async (blobUrl) => {
    try {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      return blob.size;
    } catch (error) {
      console.error("Error fetching blob size:", error);
      return null;
    }
  };

  const generateImageSrc = (imageValue) => {
    const imgURL = URL.createObjectURL(imageValue);
    if (imageValue.type.startsWith("image/")) {
      setImageSrc(imgURL);
    } else if (imageValue.type.startsWith("video/")) {
      setVideoSrc(imgURL);
    }
  };

  const uploadImage = async (source, e, type, imageFile = null) => {
    setIsUploading(true);
    if (params?.templateId) return;
    let imageValue = null;
    if (source === "file" && imageFile) {
      imageValue = imageFile;
    } else if (source === "file") {
      if (e.target.files[0] != null) {
        imageValue = e.target.files[0];
      }
    }

    if (imageValue !== null || imageLink !== "") {
      if (imageValue) {
        generateImageSrc(imageValue);
        const size = await getBlobSize(URL.createObjectURL(imageValue));
        setMediaSize(size);
        if (size > 10 * 1024 * 1024 && imageValue.type.startsWith("video/")) {
          setIsUploading(false);
          return false;
        }
        
        await dispatchUpdateCells(null, imageValue, type, source, e);
      }
    }
    return true;
  };

  const dispatchUpdateCells = async (imageLink, imageValue, type, source, e) => {
    setIsUploading(true);
    const row = props?.attachment?.cell[1];

   await dispatch(
      updateCells({
        columnId: props?.attachment?.fieldId,
        rowIndex: props?.attachment?.rowAutonumber,
        value: imageLink ? null : imageValue,
        imageLink: imageLink,
        dataTypes: type,
        indexIdMapping: {
          [props.attachment.rowAutonumber]: row,
        },
      })
    ).then(() => {
      toast.success("Image uploaded successfully!");
    }).catch(() => {
    handleClose();

    });
    if (source === "file" && e) {
      e.target.value = null;
    } else if (source === "url") {
      setImageLink("");
    }
    setIsUploading(false);
    handleClose();

  };

  const handlePaste = (event) => {
    const items = event.clipboardData?.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const imageFile = items[i].getAsFile();
        uploadImage("file", null, "file", imageFile);
        handleClose();
        break;
      }
    }
  };
  
  useEffect(() => {
    if (props.open) {
      document.addEventListener("paste", handlePaste);
    } else {
      document.removeEventListener("paste", handlePaste);
    }
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  const classes = selectFilePopupStyles();
  // const dispatch=useDispatch();
  const [uploadOption, setUploadOption] = useState("file");
  const handleClose = () => {
    
    props.setOpen(false);
  };
  const handleFileSelection = async(e) => {
    const closePopup = await uploadImage('file',e, "file");
    if(closePopup){
      handleClose();
    }
  };

   const deleteImage = async(imageUrl)=>{
    dispatch(
      updateCells({
        columnId: props?.attachment?.fieldId,
        rowIndex: props?.attachment?.rowAutonumber,
        value: {delete:[imageUrl]},
        dataTypes: null,
        indexIdMapping: {
        [props.attachment.rowAutonumber]: props?.attachment?.cell[1],       
        },
      })
    )
    props?.setOpen(state => {
      return {
        ...state, 
        d : state.d.filter(image => image !== imageUrl)
      };
    })
 }

  const handleSelectChange = (event) => {
    setImageLink('');

    setUploadOption(event.target.value);
  };
  const isUrlSelected = uploadOption === "url";
  return (
    <Box >
      <Modal
        disableRestoreFocus
        open={props?.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="popupheader" style={{ marginBottom: "5%" }}>
            <Typography sx={{ ml: 2 }} id="title" variant="h6" component="h2">
              Attachments
            </Typography>
            <CloseIcon
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={handleClose}
            />
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >

{props?.attachment?.d?.length > 0 && !videoSrc && (
  <div style={{ height: '40vh', overflowY: 'hidden', margin: '0.6rem' }}>
    <div
      // className="carousel-container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        paddingBottom: '50px'
      }}
    >
   {props?.attachment?.d?.map((link, index) => {
        const isVideo = /\.(mp4|webm|ogg)$/i.test(link);
        const isAudio = /\.(mp3|wav|ogg)$/i.test(link);
        return (
          <Box
            key={index}
            sx={{ p: 2, height: "38vh", width: "fit-content", position: "relative" }}
          >
            <a href={link} target="_blank" rel="noopener noreferrer">
              {isVideo ? (
                <ReactPlayer 
                config={{ file: { attributes: { controlsList: "nodownload" } } }} 
                url={link} playing={false}  
                controls
                 width="auto" height="34vh" 
                />
              ) : isAudio ? (
                <ReactPlayer
                  url={link}
                  playing={false}
                  controls
                  width="300px"
                  height="50px"
                  config={{ file: { attributes: { controlsList: "nodownload" } } }}
                />
              ) : (
                <img height="38vh" className="carousel-image" src={link} alt={`Image ${index + 1}`} />
              )}
              <div
                className="transparent-blur-background"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  deleteImage(link);
                }}
              >
                delete
                <DeleteOutlineIcon sx={{ fontSize: "18px" }} />
              </div>
            </a>
          </Box>
        );
      })}
    

    </div>
  </div>
)}
            <ImagePreviewAndCompress imageSrc={imageSrc} videoSrc={videoSrc} mediaSize={mediaSize} isUploading={isUploading} uploadImage={dispatchUpdateCells}/>
            {!videoSrc && 
            <>
            <Typography sx={{m:2}} variant="h6" component="h2" textAlign={'center'}>Upload a file or Paste Image  here</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Select
                labelId="select-label"
                id="select"
                value={uploadOption}
                onChange={handleSelectChange}
                defaultValue="longtext"
                displayEmpty
                sx={{
                  margin: 1,
                  minWidth: 120,
                }}
              >
                <MenuItem value="file">File</MenuItem>
                <MenuItem value="url">Url</MenuItem>
              </Select>

              {uploadOption === "url" && (
                <div style={{margin:'0.6rem'}}>
                  <CustomTextField
                    margin="dense"
                    id="text-field"
                    label="Image Link"
                    type="text"
                    value={imageLink}
                    onChange={(event) => {
                      setImageLink(event.target.value);
                    }}
                    onPaste={(event) => {
                      event.preventDefault();
                      const text = event.clipboardData.getData("text/plain");
                      setImageLink(text);

                    }}
                    sx={{width:'100%'}}
                  />
                </div>
              )}
            </div>

            <Box sx={{ display: "flex", m: 2 ,justifyContent:'space-between'}}>
              {isUrlSelected && (
                <Box>
                  <Button
                  className="mui-button"
                  variant='contained'
                    onClick={(e) => {
                      handleClose();
                      uploadImage('url',e, "file");

                    }}
                  >
                    Submit
                  </Button>
                </Box>
              )}

              {uploadOption === "file" && (
                <div style={{ display: "flex" }}>
                  <input
                    type="file"
                    id="my-file-input"
                    className={classes.input}
                    onChange={(e) => {
                      handleFileSelection(e);
                    }}
                  />
                  <label
                    htmlFor="my-file-input"
                    className={`${classes.label} mui-button`}
                    style={{ marginRight: "8px" ,borderRadius:0}}
                  >
                    Choose a file
                  </label>
                </div>
              )}
            </Box>
            </>
}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

SelectFilePopup.propTypes = {
  open: PropTypes.bool,
  attachment: PropTypes.any,
  onChangeFile:PropTypes.any,
  setOpen: PropTypes.func,
  onChangeUrl: PropTypes.func,
  imageLink: PropTypes.any,
  setImageLink: PropTypes.func,
};


